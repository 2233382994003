
.about .section-text { margin-block: 15px 30px; }

.about .btn {
  margin-inline: auto;
  margin-block-start: 26px;
}

.about .container {
  display: grid;
  gap: 120px;
}

.about-banner {
  position: relative;
  margin-block-end: 120px;
}
.container {
  padding-inline: 16px;
}
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.about-banner > .w-100 { padding-inline-start: 50px; }

.about .abs-img { position: absolute; }

.about .abs-img::before { z-index: -1; }

.about .abs-img-1 {
  bottom: -120px;
  left: 0;
  width: 150px;
  padding-block: 50px;
}

.about .abs-img-2 {
  top: -65px;
  right: 0;
  overflow: hidden;
}

.about .abs-img-1::before {
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 140px;
  background-image: url('../images/img-pattern.svg');
  background-repeat: repeat;
}

.about .abs-img-2::before {
  inset: 0;
  background-image: url('../images/badge-2-bg.png');
  background-repeat: no-repeat;
  background-size: contain;
  animation: rotate360 15s linear infinite;
}

/*-----------------------------------*\
  #REUSED STYLE
\*-----------------------------------*/

.container { padding-inline: 16px; }

.separator {
  width: 8px;
  height: 8px;
  border: 1px solid var(--gold-crayola);
  transform: rotate(45deg);
}

.contact-label { font-weight: var(--weight-bold); }

.contact-number {
  color: var(--gold-crayola);
  max-width: max-content;
  margin-inline: auto;
}

.hover-underline {
  position: relative;
  max-width: max-content;
}

.hover-underline::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-block: 1px solid var(--gold-crayola);
  transform: scaleX(0.2);
  opacity: 0;
  transition: var(--transition-2);
}

.hover-underline:is(:hover, :focus-visible)::after {
  transform: scaleX(1);
  opacity: 1;
}

.contact-number::after { bottom: -5px; }

.text-center { text-align: center; }



.section-subtitle::after {
  content: url('../images/separator.svg');
  display: block;
  width: 100px;
  margin-inline: auto;
  margin-block-start: 5px;
}



.section {
  position: relative;
  padding-block: var(--section-space);
  overflow: hidden;
  z-index: 1;
}

.bg-black-10 { background-color: var(--smoky-black-2); }

.grid-list {
  display: grid;
  gap: 40px;
}

.hover\:shine { position: relative; }

.hover\:shine::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background-image: linear-gradient(to right, transparent 0%, #fff6 100%);
  transform: skewX(-0.08turn) translateX(-180%);
}

.hover\:shine:is(:hover, :focus-within)::after {
  transform: skewX(-0.08turn) translateX(275%);
  transition: var(--transition-3);
}

.img-holder {
  aspect-ratio: var(--width) / var(--height);
  overflow: hidden;
  background-color: var(--eerie-black-4);
}

.btn-text {
  color: var(--gold-crayola);
  padding-block-end: 4px;
  margin-inline: auto;
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-3);
  font-weight: var(--weight-bold);
  transition: var(--transition-1);
}

.btn-text:is(:hover, :focus-visible) { color: var(--white); }

.shape {
  display: none;
  position: absolute;
  max-width: max-content;
  z-index: -1;
}

.w-100 { width: 100%; }

.move-anim { animation: move 5s linear infinite; }

@keyframes move {
  0%,
  100% { transform: translateY(0); }

  50% { transform: translateY(30px); }
}

.has-bg-image {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.input-field {
  background-color: var(--eerie-black-2);
  color: var(--white);
  height: 56px;
  padding: 10px 20px;
  border: 1px solid var(--white-alpha-10);
  margin-block-end: 20px;
  outline: none;
  transition: border-color var(--transition-2);
}

.input-field::placeholder { color: inherit; }

.input-field:focus { border-color: var(--gold-crayola); }

.icon-wrapper {
  position: relative;
  margin-block-end: 20px;
}

.icon-wrapper .input-field {
  margin-block-end: 0;
  padding-inline-start: 40px;
  appearance: none;
  cursor: pointer;
}

.icon-wrapper ion-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.4rem;
  --ionicon-stroke-width: 50px;
  pointer-events: none;
}

.icon-wrapper ion-icon:first-child { left: 15px; }

.icon-wrapper ion-icon:last-child { right: 10px; }


/*-----------------------------------*\
  #RESET
\*-----------------------------------*/

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a,
img,
data,
span,
input,
button,
select,
ion-icon,
textarea { display: block; }

a {
  color: inherit;
  text-decoration: none;
}
img { height: auto; }

input,
button,
select,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
select,
textarea {
  width: 100%;
  outline: none;
}

button { cursor: pointer; }

address { font-style: normal; }

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

