
.footer-top { margin-block-end: 70px; }

.footer-brand {
  position: relative;
  padding: 50px 40px;
  background: var(--smoky-black-1) url("../images/footer-form-bg.png");
  background-position: center top;
  background-repeat: repeat;
}

.footer-brand::before,
.footer-brand::after {
  top: 0;
  width: 15px;
  height: 100%;
  background-image: url("../images/footer-form-pattern.svg");
}

.footer-brand::before { left: 0; }

.footer-brand::after { right: 0; }

.footer-brand .logo {
  max-width: max-content;
  margin-inline: auto;
  margin-block-end: 40px;
}

.footer-brand :is(.body-4, .label-1) {
  color: var(--quick-silver);
  line-height: var(--lineHeight-3);
}

.footer-brand .contact-link {
  margin-block: 6px;
  transition: var(--transition-1);
}

.footer-brand .contact-link:is(:hover, :focus-visible) { color: var(--gold-crayola); }

.footer-brand .wrapper {
  display: flex;
  justify-content: center;
  gap: 2px;
  margin-block: 40px 25px;
}

.footer-brand .separator { animation: rotate360 15s linear infinite; }

.footer-brand .label-1 { margin-block-end: 30px; }

.footer-brand .label-1 .span {
  display: inline;
  color: var(--white);
}

.footer-brand .icon-wrapper .input-field { cursor: text; }

.footer-brand .btn { min-width: 100%; }

.footer-list {
  display: grid;
  gap: 20px;
}

.footer-link {
  color: var(--quick-silver);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-4);
  margin-inline: auto;
  transition: var(--transition-1);
}

.footer-link:is(:hover, :focus-visible) { color: var(--gold-crayola); }

.copyright {
  color: var(--quick-silver);
  line-height: var(--lineHeight-3);
}

.copyright .link {
  display: inline;
  color: var(--gold-crayola);
  text-decoration: underline;
}

.footer-text-main{
  font-size: 3rem;
  margin:1rem 0;
}