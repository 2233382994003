* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

header{
    display:flex;
    align-items: center;
    justify-content: space-between;
    height:80px;
    padding: 0 2rem;
    background-color: black;
    color:white;
    font-size:3rem;
    position: fixed;
    top: 10;
    left: 0;
    z-index: 9;
    width: 100%;
}
header .logo_img{
    width: 75px;
    height: 75px;
}
header h3{
    font-size:2.5rem;
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-weight:bold;
}
nav a{
    margin: 0 1rem;
    color: var(--gold-crayola);
    text-decoration:none;
    text-transform: uppercase;
   
}
.nav a:hover{
    color: red;
}
header .nav-btn{
    padding: 5px;
    cursor:pointer;
    background:transparent;
    border:none;
    outline:none;
    color: white;
    visibility:hidden;
    opacity:0;
    font-size: 1.8rem;
}
header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px){
    header .nav-btn{
        visibility:visible;
        opacity:1;
    }

    header nav{
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: var(--eerie-black-1);
        transition: 1s;
        transform: translateY(-100vh);
    }
header .responsive_nav{
    transform: none;
    z-index:9;
}

nav .nav-close-btn{
    position: absolute;
    top: 2rem;
    right: 2rem;
}
nav a{
    font-size: 2.5rem;
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

}

