
.hero .slider-btn { display: none; }

.hero {
  position: relative;
  padding-block: 120px;
  min-height: 100vh;
  overflow: hidden;
  z-index: 1;
  color:white;
}
.hero-title{
  font-size:4rem;
  text-transform:uppercase;
  font-family: 'Tiro Devanagari Hindi';
  text-align: center;
}
.main-title{
  font-size:5rem;
}
.simple-text{
  letter-spacing:0;
  font-size:2rem;
  font-weight: 200;
  margin:0;
}

.hero .slider-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: grid;
  place-content: center;
  opacity: 0;
  visibility: hidden;
  transition: var(--transition-3);
  z-index: 1;
}

.hero .slider-item.active {
  opacity: 1;
  visibility: visible;
}

.hero .slider-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(1.15);
  pointer-events: none;
  user-select: none;
  z-index: -1;
}

.main_img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px; /* Set container height */
  z-index: -1;
  padding : 1rem;
  margin-bottom : 2rem;
}

.logo_img {
  max-width: 100%; /* Ensure image doesn't exceed container width */
  max-height: 100%; /* Ensure image doesn't exceed container height */
  object-fit: contain; /* Fit the image inside its container without distortion */
  border-radius: 50%;
  border:1px solid white;
}

.hero .slider-item.active .slider-bg .main_img{
  animation: smoothScale 7s linear forwards;
}

@keyframes smoothScale {
  0% { transform: scale(1); }
  100% { transform: scale(1.15); }
}

.hero .section-subtitle::after { margin-block: 14px 20px; }
.hero-text { margin-block: 10px 40px; }
.hero .btn { margin-inline: auto; }
.hero-btn {
  position: absolute;
  bottom: 15px;
  right: 15px;
  z-index: 2;
  background-color: var(--gold-crayola);
  width: 110px;
  height: 110px;
  padding: 12px;
  transform: scale(0.6);
}

.hero-btn img {
  margin-inline: auto;
  margin-block-end: 6px;
}

.section-subtitle {
  position: relative;
  color: var(--gold-crayola);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  /* letter-spacing: var(--letterSpacing-2); */
  font-size: 2rem;
  margin-block-end: 12px;
}
.hero-btn .span {
  color: var(--black);
  font-weight: var(--weight-bold);
  text-transform: uppercase;
  letter-spacing: var(--letterSpacing-1);
  line-height: var(--lineHeight-3);
}

.has-before::before,
.has-after::after {
  content: "";
  position: absolute;
}
.hero-btn::after {
  inset: 0;
  border: 1px solid var(--gold-crayola);
  animation: rotate360 15s linear infinite;
}



.slider-reveal {
  transform: translateY(30px);
  opacity: 0;
 margin: 0;
}

.hero .slider-item.active .slider-reveal {
  animation: sliderReveal 1s ease forwards;
}

@keyframes sliderReveal {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.hero .slider-item.active .section-subtitle { animation-delay: 500ms; }

.hero .slider-item.active .hero-title { animation-delay: 1000ms; }

.hero .slider-item.active .hero-text { animation-delay: 1.5s; }

.hero .slider-item.active .btn { animation-delay: 2s; }

@media (min-width: 1200px){
  .hero-title{
    letter-spacing:1rem;
    margin-bottom: 5rem;
  }
}


