.menu .section-title {
  margin-block-end: 40px;
}

.menu .grid-list {
  margin-block-end: 50px;
}
.img-cover {
    width: 100px;
    height: 100px;
    object-fit: cover;
}
.menu-card{
  display: flex;
  align-items: flex-start;
  gap: 20px;
}

.hover\:card .card-banner {
  background-color: var(--gold-crayola);
}

.hover\:card .card-banner .img-cover {
  transition: var(--transition-2);
}

.hover\:card:is(:hover, :focus-within) .card-banner .img-cover {
  opacity: 0.7;
  transform: scale(1.05);
}

.menu-card .card-banner {
  flex-shrink: 0;
  border-radius: var(--radius-24);
}

.menu-card .card-title {
  transition: var(--transition-1);
}

.menu-card .card-title:is(:hover, :focus-visible) {
  color: var(--gold-crayola);
}

.menu-card .badge {
  background-color: var(--gold-crayola);
  color: var(--eerie-black-1);
  font-family: var(--fontFamily-forum);
  max-width: max-content;
  line-height: var(--lineHeight-6);
  text-transform: uppercase;
  padding-inline: 10px;
  margin-block-start: 10px;
}

.menu-card .span {
  color: var(--gold-crayola);
}

.menu-card .card-text {
  color: var(--quick-silver);
  line-height:2rem;
  font-size:3vw;
}

.menu-text .span {
  display: inline-block;
  color: var(--gold-crayola);
}

.menu .btn {
  margin-inline: auto;
  margin-block-start: 26px;
}

.menu .shape {
  display: block;
  width: 70%;
}

.menu .shape-2 {
  top: 0;
  left: 0;
}

.menu .shape-3 {
  bottom: 0;
  right: 0;
}
.title-3{
  font-size:1.7rem;
}

@media screen and (min-width:600px) {
  .menu-card .card-text{
    font-size:2vw;
    line-height:3vw;
  }
}